<template>
  <div class="contents">
    <!-- // サウンドをページ毎に設定 -->
    <audio
      id="bgmSound"
      :src="pageId !== undefined ? `${routePath}sounds/${pageId}-bgm.mp3` : `${routePath}sounds/bgm.mp3`"
      loop="true"
    />
    <div class="top-menu">
      <img
        class="top-menu-logo"
        :src="`${commonPath}${menuContent.logoPath}`"
        alt="代々木ゼミナール ロゴ画像"
        @click="openNewWindow(menuContent.homeUrl, false)"
      >
      <div class="top-menu-right">
        <div
          class="menu-button box-button"
          @click="openNewWindow(menuContent.contactUrl)"
        >
          <div class="button-content">
            <!-- // メールアイコン -->
            <i class="fas fa-envelope" />
            <p class="menu-button-text">
              お問い合わせ
            </p>
          </div>
        </div>
        <div
          class="menu-button box-button"
          @click="openInNewWindow(menuContent.infoUrl)"
        >
          <div class="button-content">
            <!-- // はてなアイコン -->
            <i class="fas fa-question-circle" />
            <p class="menu-button-text">
              使用方法
            </p>
          </div>
        </div>
        <div
          class="menu-button "
          @click="(bgmStatus) ? setBgmStatus(false) : setBgmStatus(true);"
        >
          <div class="button-content">
            <template v-if="bgmStatus">
              <div class="button-content circle-button volume--on">
                <i class="fas fa-volume-up" />
                <div>ON</div>
              </div>
            </template>
            <template v-else>
              <div class="button-content circle-button volume--off">
                <i class="fas fa-volume-mute" />
                <div>OFF</div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="vr-scene">
      <a-scene id="scene">
        <a-assets
          v-if="pageId === 'main-floor'"
          id="assets-area"
        >
          <video
            v-for="(face, index) in faceList"
            :id="index"
            :key="index"
            :src="`${routePath}face/${face}`"
            preload="auto"
            playsinline
            autoplay
            muted
            loop
          />
        </a-assets>
        <RoomScene
          ref="room"
          :image-route-path="routePath + 'images/'"
          :glb-route-path="routePath + 'glb/'"
          :json-data="jsonData"
          @setGlbData="setGlbData"
          @loadFlag="loadFlag = true"
          @setConfirmWindow="setConfirmWindow"
        />
        <ControlButton 
          v-if="roomRig && roomCamera && isTouchDevice"
          :json-rotation-y="jsonRotationY"
          :room-rig="roomRig"
          :room-camera="roomCamera"
        />
        <!-- 初期位置落下防止用床 -->
        <a-plane 
          v-if="jsonData.modelName === 'yozemi'"
          id="yozemi-prevFloor"
          static-body 
          position="-2 0.4 7" 
          rotation="-90 0 0" 
          width="1" 
          height="1" 
          material="opacity:0" 
        />
        <!-- アバター -->
        <a-entity
          v-if="pageId === 'main-floor'"
          id="avatar-parts"
        >
          <a-entity
            v-for="(avatar, index) in avatarData"
            :id="index"
            :key="index"
            :position="avatar.position"
            :rotation="avatar.rotation"
            @click="setConfirmWindow(index)"
          >
            <a-entity
              :gltf-model="avatar.model"
              rotation="0 180 0"
            />
            <a-video
              v-if="avatar.type === 'video'"
              :src="`#${avatar.face}`"
              position="0 1.03 0.175"
              width="0.6"
              height="0.34"
            />
          </a-entity>
        </a-entity>
      </a-scene>
    </div>
    <LoadingView v-if="!loadFlag" />
    <SoundPermission
      v-if="soundPermissionFlag"
      @sound-permission="setBgmStatus(true)"
    />
    <ConfirmWindow
      v-if="confirmWindowFlag"
      :target-title="targetTitle"
      :target-url="targetUrl"
      :window-open="windowTabFlag"
      @close-window="confirmWindowFlag = false"
    />
  </div>
</template>

<script>
import utilsMixin from '../mixins/utils'
import LoadingView from '../components/LoadingView'
import SoundPermission from '../components/SoundPermission'
import ConfirmWindow from '../components/ConfirmWindow'
import RoomScene from '../components/RoomScene'
import ControlButton from '../components/ControlButton'
import settingJson from '../assets/setting-yozemi.json';

export default {
  name: 'YozemiView',
  components: {
    RoomScene,
    ControlButton,
    LoadingView,
    SoundPermission,
    ConfirmWindow
  },
  mixins: [utilsMixin],
  props: {},
  data() {
    return {
      title: '代々木ゼミナール',  // ページタイトル
      description: '代々木ゼミナール 学校案内メタサイト',  // ページ説明
      loadFlag: false,                                    // ロード画面用フラグ
      confirmWindowFlag: false,                           // 遷移先確認画面表示フラグ
      soundPermissionFlag: false,                         // 音声許可ウィンドウ表示フラグ
      targetTitle: '',                                    // 遷移先タイトル
      targetUrl: '',                                      // 遷移先URL
      windowTabFlag: true,                                // 別タブ起動フラグ true->別タグ
      allJsonData: settingJson,                           // 全てのページの設定情報
      jsonData: '',                                       // ページごとの設定情報
      pageId: this.$route.params.page,                    // ページID
      prevPageId: this.$route.query.prev,                 // 一つ前のページID
      commonPath: '/assets/yozemi/',               // 各データの共通パス
      routePath: '/assets/yozemi/',                // 各データのルートパス
      secondaryRoutePath: '/assets/yozemi-page/',  // 子階層の各データのルートパス
      glbData: {},                                        // 子Vueで取得したGLBデータ
      delWarpGlbList: [],                                 // ワープゾーンの土台GLB(使っていないものは削除するために保持)
      faceList: {   // 顔動画
        dummy1: 'dummy1.mp4',
        dummy2: 'dummy2.mp4',
        dummy3: 'dummy3.mp4',
        dummy4: 'dummy4.mp4',
        dummy5: 'dummy5.mp4'
      },
      avatarData: { // アバターの情報
        'inquiry-floor': {  // 受付アバター
          model: '/glb/simple-avatar/office-woman.glb',
          type: 'video',
          face: 'dummy1',
          position: '10.784 0 7.464',
          rotation: '0 270 0'
        },
        'inquiry-school': {  // 大学案内アバター
          model: '/glb/simple-avatar/job-bus-guide.glb',
          type: 'video',
          face: 'dummy2',
          position: '8.27 0 -12',
          rotation: '0 330 0'
        },
        'avatar-3': {  // アバター3
          model: '/glb/simple-avatar/school-boy.glb',
          type: 'video',
          face: 'dummy3',
          position: '0 0 -6.561',
          rotation: '0 30 0'
        },
        'avatar-4': {  // アバター4
          model: '/glb/simple-avatar/school-girl.glb',
          type: 'video',
          face: 'dummy4',
          position: '0.931 0 -6.561',
          rotation: '0 330 0'
        },
        'avatar-5': {  // アバター5
          model: '/glb/simple-avatar/reception-woman.glb',
          type: 'video',
          face: 'dummy5',
          position: '4.37 0 10',
          rotation: '0 30 0'
        }
      },
      bldgFaceList: {   // 顔動画
        dummy1: 'dummy1.mp4'
      },
      bldgAvatarData: { // アバターの情報
        'bldg-avatar': {  // 受付アバター
          model: '/glb/simple-avatar/office-woman.glb',
          type: 'video',
          face: 'dummy1',
          position: '-9.8 0 5.746',
          rotation: '0 0 0'
        }
      },
      camera: {     // カメラ座標(全頁共通)
        position: {
          x: 0,
          y: 1.35,
          z: 0
        },
        rotation: {
          x: 0,
          y: 0,
          z: 0
        }
      },
      pageRig: { // 子階層ページのGLBごとのrig情報
        'main-floor': { // エレベーター イベント会場2D配信用
          current: { // 順路通りに来た場合
            position: {
              x: 5.42,
              y: 0,
              z: 14
            },
            rotation: {
              x: 0,
              y: 0,
              z: 0
            }
          },
          prev: { // 前のページから戻ってきた場合
            position: {
              x: -4.69,
              y: 0,
              z: -4.88
            },
            rotation: {
              x: 0,
              y: 270,
              z: 0
            }
          }
        },
        'event': { // エレベーター イベント会場2D配信用
          current: { // 順路通りに来た場合
            position: {
              x: 0,
              y: 0,
              z: 7
            },
            rotation: {
              x: 0,
              y: 0,
              z: 0
            }
          },
          prev: { // 前のページから戻ってきた場合
            position: {
              x: 0,
              y: 0,
              z: -7.355
            },
            rotation: {
              x: 0,
              y: 180,
              z: 0
            }
          }
        },
        'bldg': { // エレベーター用
          current: { // 順路通りに来た場合
            position: {
              x: -7.4,
              y: 0,
              z: 11
            },
            rotation: {
              x: 0,
              y: 0,
              z: 0
            }
          },
          prev: { // 前のページから戻ってきた場合
            position: {
              x: 7.6,
              y: 4.6,
              z: 12.3
            },
            rotation: {
              x: 0,
              y: 0,
              z: 0
            }
          }
        }
      },
      menuContent: {
        logoPath: 'images/menu-logo.jpg',
        homeUrl: '/#/yozemi',
        contactUrl: 'https://www.yozemi.ac.jp/yozemi/toiawaseForm/init',
        infoUrl: 'https://www.vr-lite.net/#/qa?user=1'
      },
      windowActive: true, // ウィンドウがアクティブかどうかのフラグ
      bgmStatusBeforeDeactivation: true, // ウィンドウが非アクティブだったときのBGMの状態
      jsonRotationY: 0, // rigカメラのY軸回転角度
      roomRig: null, // カメラリグの参照
      roomCamera: null // カメラの参照
    }
  },
  computed: {
    firstAccess() {
      // 初回アクセスフラグを取得
      return this.$store.getters['CampusStatus/getFirstAccess'];
    },
    bgmStatus() {
      // BGM許可ステータスを取得
      return this.$store.getters['CampusStatus/getBgmStatus'];
    },
    isTouchDevice() {
      // タッチデバイス判定を取得
      return this.$store.getters['CampusStatus/getTouchDeviceStatus'];
    }
  },
  watch: {
    /*******************************************
     * BGM許可ステータスに変更がある場合
     ******************************************/
    async bgmStatus() {
      // BGMコントロール処理を呼び出し
      this.bgmControl();
    },
    /*******************************************
     * Loadフラグが更新されたらアニメーションを設定
     ******************************************/
    async loadFlag(newBool) {
      // 音声許可ポップアップを表示する判定
      if (this.firstAccess) {
        // 初回アクセスでiPhoneではない場合のみ設定
        if (!/[ \(]iP/.test(navigator.userAgent)) this.soundPermissionFlag = true;
      }
      if (newBool) {
        for (let property in this.glbData.animation) {
          // アニメーションを設定
          await this.setAnimation(property);
        }
        // リンク情報の存在しないワープGLBを非表示
        this.delWarpGlbList.forEach((elem, index) => {
          let removeGlbWarp = document.getElementById(elem);
          removeGlbWarp.setAttribute('visible', false);
        });
      }
    },
    /*******************************************
     * ウィンドウのアクティブ状態の変更を監視
     ******************************************/
    windowActive(newActiveState) {
      // ウィンドウが非アクティブになったとき
      if (!newActiveState) {
        // ウィンドウが非アクティブになる直前のBGMの状態を保存
        this.bgmStatusBeforeDeactivation = this.bgmStatus;
        // BGMをミュートにする
        this.setBgmStatus(false);
      } else {
        // ウィンドウがアクティブになったとき
        // ウィンドウが非アクティブだったときのBGMの状態に戻す
        this.setBgmStatus(this.bgmStatusBeforeDeactivation);
      }
    }
  },
  created() {
    // ページIDが存在しない場合yozemiの処理を行う
    if (this.pageId === undefined) {
      // yozemiの情報を設定
      this.jsonData = this.allJsonData.yozemi;
      // ワープゾーンから戻ってきた場合の出現位置を設定
      if (this.prevPageId !== undefined) {
        let prev = this.prevPageId.replace(/[^a-zA-Z-]/gi, '');
        this.jsonData.rig = this.jsonData.prevRig[prev];
      }
    } else if (this.allJsonData[this.pageId] === undefined) {
      // ページが存在しない場合はメインフロアを表示
      this.jsonData = this.allJsonData.yozemi;
      this.$router.push({ name: 'yozemi' });
      setTimeout(function () {
        location.reload();
      }, 10);
    } else {
      // ルートパスを上書き
      this.routePath = this.secondaryRoutePath;
      // ページ情報を設定
      this.jsonData = this.allJsonData[this.pageId];
      // ページidから数字を抽出
      let prevNum = 0;
      let pageNum = this.pageId.replace(/[^0-9]/gi, '');
      if (this.prevPageId !== undefined) prevNum = this.prevPageId.replace(/[^0-9]/gi, '');
      // prevが現在のページより大きい数の場合rigを上書き
      if (pageNum < prevNum) {
        // 前のページから戻ってきた場合
        this.jsonData.rig = this.pageRig[this.jsonData.modelName].prev;
      } else if (this.pageId === 'main-floor' && this.prevPageId === 'event') {
        // 'main-floor' ページかつeventページから戻ってきた('prev=event')場合rigを上書き
        this.jsonData.rig = this.pageRig[this.jsonData.modelName].prev; // 'event' から戻った場合の位置
      } else {
        // それ以外
        this.jsonData.rig = this.pageRig[this.jsonData.modelName].current;
      }
    }
    // カメラ座標を設定
    this.jsonData['camera'] = this.camera;
  },
  mounted() {
    // BGMコントロール処理を呼び出し
    this.bgmControl();
    // タイトルと説明、OGPを設定
    this.changePageContent();
    // ページがメインフロアの場合、顔動画を再生
    if (this.pageId === 'main-floor') {
      for (const video in this.faceList) {
        let videoElement = document.getElementById(video);
        if (videoElement) {
          videoElement.play();
        } else {
          console.warn(`Video element with ID ${video} not found.`);
        }
      }
    }
    // コントロールボタン操作に必要な情報を設定
    this.jsonRotationY = this.jsonData.rig.rotation.y; // jsonDataからrigのY軸回転角度を取得しjsonRotationYに設定
    this.roomRig = this.$refs.room.$refs.rig; // roomコンポーネントからrigエレメントを取得しroomRigに設定
    this.roomCamera = this.$refs.room.$refs.camera; // roomコンポーネントからcameraエレメントを取得しroomCameraに設定
    /*******************************************
     * ウィンドウのフォーカスイベントを監視
     ******************************************/
    window.addEventListener('focus', () => {
      this.windowActive = true;
    });
    // ウィンドウのブラーイベントを監視
    window.addEventListener('blur', () => {
      this.windowActive = false;
    });
    /*******************************************
     * ワープゾーンのポジション設定
     ******************************************/
    const positionChanged = (newPosition) => {
      // 設定されているワープゾーンの数だけ繰り返しチェック
      for (let property in this.jsonData.warpList) {
        // 設定ファイルからワープゾーンのポジションを取得
        let xWarpSpot = Math.ceil(this.jsonData.warpList[property].position.x);
        let yWarpSpot = Math.ceil(this.jsonData.warpList[property].position.y);
        let zWarpSpot = Math.ceil(this.jsonData.warpList[property].position.z);
        // ワープゾーンに来たら確認ポップアップを起動(原点から±1m以内の範囲)
        if (
          (newPosition.x >= xWarpSpot - 1 && newPosition.x <= xWarpSpot + 1) &&
          (newPosition.y >= yWarpSpot - 1 && newPosition.y <= yWarpSpot + 1) &&
          (newPosition.z >= zWarpSpot - 1 && newPosition.z <= zWarpSpot + 1)
        ) {
          // ポップアップの処理を呼び出し
          this.setWarpConfirmWindow(property);
        }
      }
    };
    // ポジションを見張る処理
    window.AFRAME.registerComponent('position-listener', {
      tick() {
        // 現在のポジションを取得
        const newValue = this.el.getAttribute('position');
        // 小数点を四捨五入
        const newPosition = {
          x: Math.ceil(newValue.x),
          y: Math.ceil(newValue.y),
          z: Math.ceil(newValue.z)
        }
        // 文字列に置き換え
        const stringCoords = window.AFRAME.utils.coordinates.stringify({
          x: newPosition.x,
          y: newPosition.y,
          z: newPosition.z
        });
        // ポジションが変更しているかチェック
        if (this.lastValue !== stringCoords) {
          this.lastValue = stringCoords;
          // 変更がある場合はワープの位置をチェック
          positionChanged(newPosition);
        }
      }
    });
    // /*******************************************
    //  * 外部のjsファイルを読み込む
    //  * zoho CHAT
    // ******************************************/
    // // スクリプト要素を作成
    // const script1 = document.createElement('script');
    // script1.innerHTML = `
    //   window.$zoho=window.$zoho || {};$zoho.salesiq=$zoho.salesiq||{ready:function(){}}
    // `;
    // // スクリプト要素を作成
    // const script2 = document.createElement('script');
    // script2.src = 'https://salesiq.zohopublic.jp/widget?wc=8c9b86c3c1ffacdf0dc2e31edd64914e46dc221e7a9825ba0ae41e58c7158d6c';
    // script2.id = 'zsiqscript';
    // script2.defer = true;
    // // ドキュメントのヘッドにスクリプトを追加
    // document.head.appendChild(script1);
    // document.head.appendChild(script2);
  },
  methods: {
    /*******************************************
     * OGPを設定
     ******************************************/
    // ogImagePathを生成する
    ogImagePath() {
      const uri = new URL(window.location.href);
      if (this.pageId === undefined) {
        return `${uri.origin}${this.routePath}images/og_image.png`;
      } else {
        return `${uri.origin}${this.secondaryRoutePath}images/og_image-${this.pageId}.png`;
      }
    },
    /*******************************************
     * BGM許可ステータスを設定
     ******************************************/
    setBgmStatus(newBool) {
      this.$store.dispatch('CampusStatus/setBgmStatus', newBool);
      this.soundPermissionFlag = false;
      // BGMコントロール処理を呼び出し
      this.bgmControl();
    },
    /*******************************************
     * フラグに変更があれば再生or一時停止処理
     ******************************************/
    async bgmControl() {
      const bgmSound = document.getElementById('bgmSound');
      if (Boolean(this.bgmStatus)) {
        // 再生処理
        try {
          // BGMを再生
          await bgmSound.play();
          // 初回アクセスの場合は書き換え
          if (this.firstAccess) this.$store.dispatch('CampusStatus/setFirstAccess', false);
        } catch (err) {
          // 再生に失敗した場合はフラグを書き換え
          this.setBgmStatus(false);
        }
      } else {
        // 一時停止処理
        bgmSound.pause();
      }
    },
    /*******************************************
     * 子から受け取った建物データを設定
     ******************************************/
    setGlbData(glbData) {
      this.glbData = glbData;
    },
    /*******************************************
     * 別タブでURLを起動
     ******************************************/
    openNewWindow(target, windw = true) {
      if (windw) {
        window.open(target);
      } else {
        location.href = target
        location.reload();
      }
    },
    /*******************************************
     * 別ウインドウでURLを起動
     ******************************************/
    openInNewWindow(target) {
      // 新しいウィンドウのサイズ
      const newWindowWidth = 800;
      const newWindowHeight = 600;
      // 画面サイズを取得
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      // 右下に位置を設定
      const left = screenWidth - newWindowWidth; // 画面の右端からウィンドウの幅を引いた位置
      const top = screenHeight - newWindowHeight; // 画面の下端からウィンドウの高さを引いた位置
      window.open(target, '_blank', `width=${newWindowWidth},height=${newWindowHeight},top=${top},left=${left}`);
    },
    /*******************************************
     * 画面遷移のConfirm画面呼び出し
     ******************************************/
    setConfirmWindow(target) {
      // 設定ファイルからタイトルとURLを取得
      this.targetTitle = this.jsonData.linkList[target].title;
      this.targetUrl = this.jsonData.linkList[target].url;
      // confirmウィンドウを起動
      this.confirmWindowFlag = true;
    },
    /*******************************************
     * ワープのConfirm画面呼び出し
     ******************************************/
    setWarpConfirmWindow(target) {
      // URL形式
      if (this.jsonData.warpList[target].url.indexOf('://') === -1) {
        // 自サイトの場合ホストを設定
        this.targetUrl = window.location.protocol + '//' + window.location.host + '/#';
        this.targetUrl += this.jsonData.warpList[target].url;
        // pageIDがある場合はパラメーターに設定
        if (this.pageId !== undefined) this.targetUrl += '?prev=' + this.pageId;
        this.windowTabFlag = false;
      } else {
        // 他サイトの場合はそのままURLを設定
        this.targetUrl = this.jsonData.warpList[target].url;
      }
      // タイトルを設定
      this.targetTitle = this.jsonData.warpList[target].title;
      // confirmウィンドウを起動
      this.confirmWindowFlag = true;
    },
    /*******************************************
     * パーツごとにアニメーションを設定する
     ******************************************/
    setAnimation(animationName) {
      let animationElement = document.getElementById(animationName);
      // ドア左の開くアニメーション
      if (animationElement.id === 'animation-nobody-door-1') {
        animationElement.setAttribute('animation-mixer', 'loop:once;clampWhenFinished:true;clip:open-1;');
      }
      // ドア右の開くアニメーション
      if (animationElement.id === 'animation-nobody-door-2') {
        animationElement.setAttribute('animation-mixer', 'loop:once;clampWhenFinished:true;clip:open-2;');
      }
      // ドア左の開くアニメーション
      if (animationElement.id === 'animation-door-l') {
        animationElement.setAttribute('animation', 'property: object3D.position.x;to: -1.1;dir: alternate;dur: 2000;loop: false');
      }
      // ドア右の開くアニメーション
      if (animationElement.id === 'animation-door-r') {
        animationElement.setAttribute('animation', 'property: object3D.position.x;to: 1.1;dir: alternate;dur: 2000;loop: false');
      }
      // 建物内の矢印を前後に動かす
      if (animationElement.id === 'animation-nobody-arrow') {
        animationElement.setAttribute('animation', 'property: object3D.position.z;to: -0.5;dir: alternate;dur: 1000;loop: true');
      }

      // ワープ(マーク)のアニメーション(-nなし)
      if (animationElement.id === 'animation-warp-mark') {
        // 原点にあるものは削除
        animationElement.remove();

        // GLBファイルの保存先URLを取得
        const glbWarpPath =
          this.glbData.animation[animationElement.id] &&
          this.glbData.animation[animationElement.id].path; // パスが存在する場合のみ取得

        // ワープゾーン(土台)のGLB名を全て取得（すでに設定されている場合は取得しない）
        if (this.delWarpGlbList.length === 0) {
          this.delWarpGlbList = Object.keys(this.glbData.common).filter((key) => {
            return key.match(/^nobody-warp-[0-9]/);
          });
        }

        // シーンの取得
        const scene = document.getElementById('scene');

        // 設定ファイルのwarpListを繰り返し処理
        for (let property in this.jsonData.warpList) {
          let warpData = this.jsonData.warpList[property];

          // warp-[n] の末尾の数字を取得
          const match = property.match(/\d+$/);
          const warpNumber = match ? match[0] : null; // マッチする数字があれば取得、なければ null

          // nobody-warp-[n] の末尾の数字と一致する場合の処理
          const matchingWarp = this.delWarpGlbList.find((glb) => glb.endsWith(`-${warpNumber}`));
          if (matchingWarp) {
            this.delWarpGlbList = this.delWarpGlbList.filter((glb) => glb !== matchingWarp);
          } else if (property === 'warp') {
            // nobody-warp があり、warpList.warp の場合
            const warpEntity = document.createElement('a-entity');
            warpEntity.setAttribute('id', 'nobody-warp');

            // ポジション設定
            if (warpData.position) {
              warpEntity.setAttribute('position', warpData.position);
              // アニメーションの設定
              warpEntity.setAttribute(
                'animation',
                `property: object3D.position.y; from: ${warpData.position.y}; to: ${warpData.position.y - 0.3}; dir: alternate; dur: 1000; loop: true`
              );
            } else {
              console.warn(`Position not defined for warp: ${property}`);
            }

            if (warpData.rotation) {
              warp.setAttribute('rotation', warpData.rotation);
            }

            // モデル設定（GLBパスがあれば設定）
            if (glbWarpPath) {
              warpEntity.setAttribute('gltf-model', glbWarpPath);
            }

            warpEntity.setAttribute('static-body', 'shape:none;');

            // シーンに追加
            scene.appendChild(warpEntity);
            continue;
          } else {
            console.warn(`No matching warp found for warp-${property}`);
            continue;
          }

          // nobody-warp-[n] の場合の通常処理
          let warp = document.createElement('a-entity');
          warp.setAttribute('id', property);

          // GLBモデルの設定
          if (glbWarpPath) {
            warp.setAttribute('gltf-model', glbWarpPath);
          }

          // 位置の設定
          if (warpData.position) {
            const positionY = typeof warpData.position.y === 'number' ? warpData.position.y : 0;
            warp.setAttribute('position', warpData.position);

            // アニメーションの設定
            warp.setAttribute(
              'animation',
              `property: object3D.position.y; from: ${positionY}; to: ${positionY - 0.3}; dir: alternate; dur: 1000; loop: true`
            );
          }

          if (warpData.rotation) {
            warp.setAttribute('rotation', warpData.rotation);
          }

          warp.setAttribute('static-body', 'shape:none;');

          // シーンに追加
          scene.appendChild(warp);
        }

      }
    }
  }
}
</script>

<style scoped>
.contents {
  height: 100vh;
}

.vr-scene {
  height: calc(100vh - 75px);
  /* 100vh - .top-menu の height */
}

.top-menu {
  width: 100%;
  height: 75px;
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: center;
  padding: 10px;
}

.top-menu-logo {
  cursor: pointer;
  width: auto;
  max-height: 55px;
  padding: 10px;
}

.top-menu-right {
  display: grid;
  grid-template-columns: minmax(150px, auto) minmax(130px, auto) 50px;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  margin-left: auto;
}

.menu-button {
  cursor: pointer;
  background-color: #fff;
  transition: all ease 0.2s;
}

.menu-button:last-child {
  height: 50px;
  margin-right: 0;
}

.menu-button-text {
  color: #e70012;
}

.button-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.box-button {
  color: #e70012;
  line-height: 24px;
  font-weight: bold;
  background-color: #fff;
  border: 1px solid #e70012;
  border-radius: 50px;
  padding: 8px 32px;
  transition: all ease 0.2s;
}

.box-button:hover {
  color: #fff;
  background-color: #e70012;
}

.box-button:hover .menu-button-text {
  color: #fff;
}

.box-button .button-content {
  letter-spacing: 0.5px;
  display: grid;
  grid-template-columns: 20px 1fr;
  column-gap: 0.5em;
}

.circle-button {
  width: 50px;
  line-height: 0.85em;
  border: thin solid #202020;
  border-radius: 50%;
}

.circle-button:hover {
  color: #fff;
  background-color: #202020;
}

.circle-button.volume--on {
  color: #fff;
  background-color: #202020;
}

.circle-button-text {
  font-size: 0.35em;
  white-space: nowrap;
}

@media screen and (max-width:767px) {
  .top-menu {
    grid-template-columns: 110px 1fr;
    padding: 10px 5px;
  }

  .top-menu-logo {
    max-width: 100%;
    padding: 5px;
  }

  .top-menu-right {
    grid-column-gap: 5px;
  }

  .box-button {
    font-size: 90%;
    padding: 8px 16px;
  }

  .box-button .button-content {
    display: flex;
  }
}

@media screen and (max-width:428px) {

  /* SP 縦画面 */
  .top-menu {
    grid-template-columns: 90px 1fr;
    padding: 10px 5px;
  }

  .top-menu-right {
    grid-template-columns: 110px 100px 50px;
    grid-column-gap: 5px;
  }

  .box-button {
    font-size: 80%;
    line-height: 1;
    padding: 8px 12px;
  }

}
</style>